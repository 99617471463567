import { render, staticRenderFns } from "./RevisionDireccion.vue?vue&type=template&id=07894084&"
import script from "./RevisionDireccion.vue?vue&type=script&lang=js&"
export * from "./RevisionDireccion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports