<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS REVISIONES POR LA DIRECCIÓN</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico far fa-check-square fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaRevisiones.length}}</span>
                            <br>
                            <span class="text-muted">Revisiones registradas</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de revisiones</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('010-MEJ-REV','c')==1" cols="4" class="my-2">
                            <b-button block :to="{name: 'Gestión de revisión por la dirección'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva revisión por la dirección
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Revisiones registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaRevisiones" :fields="campoRevisiones" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(acciones)="param">
                                    <b-button v-if="checkPermissions('010-MEJ-REV','u')==1" :to="{name: 'Gestión de revisión por la dirección', params: {id: param.item.idRevisionDireccion}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button :disabled="disabled" @click="generarDocumento(param)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                        <i class="fas fa-download my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('010-MEJ-REV','d')==1" @click="eliminarRevision(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <!-- <template #cell(conseguido)="data">
                                    <b-icon variant="success" v-if="data.value=='si'" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template> -->
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

export default {
    components: {

    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoRevisiones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaRevisiones: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false
        }
    },
    methods: {

        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarRevisionesDireccion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-revisiones-direccion", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaRevisiones = response.data;
                    me.totalRows = me.listaRevisiones.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        /* 
                convertirBase64(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function () {
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                }, */
        generarDocumento(param) {

            let me = this;
            me.disabled = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/generar-documento-revision", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idRevisionDireccion: param.item.idRevisionDireccion,
                        fecha: param.item.fecha,
                    }

                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                })
                .then(function (response) {
                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}/plantillas/plantilla-revision-direccion.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [170, 80];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            numeroRevision: response.data.datosAsistentes[0].numeroRevision,
                            fecha: response.data.datosAsistentes[0].fecha,
                            horaInicio: response.data.datosAsistentes[0].horaInicio,
                            horaFin: response.data.datosAsistentes[0].horaFin,
                            oportunidadesMejora: response.data.datosAsistentes[0].oportunidadesMejora,
                            listaAsistentes: response.data.datosAsistentes,

                            entrada1: response.data.datoPuntosTratados[0].entradaConclusiones,
                            anexo1: response.data.datoPuntosTratados[0].anexosRegistros,
                            mejora1: response.data.datoPuntosTratados[0].mejorasPropuestas,

                            entrada2: response.data.datoPuntosTratados[1].entradaConclusiones,
                            anexo2: response.data.datoPuntosTratados[1].anexosRegistros,
                            mejora2: response.data.datoPuntosTratados[1].mejorasPropuestas,

                            entrada3: response.data.datoPuntosTratados[2].entradaConclusiones,
                            anexo3: response.data.datoPuntosTratados[2].anexosRegistros,
                            mejora3: response.data.datoPuntosTratados[2].mejorasPropuestas,

                            entrada4: response.data.datoPuntosTratados[3].entradaConclusiones,
                            anexo4: response.data.datoPuntosTratados[3].anexosRegistros,
                            mejora4: response.data.datoPuntosTratados[3].mejorasPropuestas,

                            entrada5: response.data.datoPuntosTratados[4].entradaConclusiones,
                            anexo5: response.data.datoPuntosTratados[4].anexosRegistros,
                            mejora5: response.data.datoPuntosTratados[4].mejorasPropuestas,

                            entrada6: response.data.datoPuntosTratados[5].entradaConclusiones,
                            anexo6: response.data.datoPuntosTratados[5].anexosRegistros,
                            mejora6: response.data.datoPuntosTratados[5].mejorasPropuestas,

                            entrada7: response.data.datoPuntosTratados[6].entradaConclusiones,
                            anexo7: response.data.datoPuntosTratados[6].anexosRegistros,
                            mejora7: response.data.datoPuntosTratados[6].mejorasPropuestas,

                            entrada8: response.data.datoPuntosTratados[7].entradaConclusiones,
                            anexo8: response.data.datoPuntosTratados[7].anexosRegistros,
                            mejora8: response.data.datoPuntosTratados[7].mejorasPropuestas,

                            entrada9: response.data.datoPuntosTratados[8].entradaConclusiones,
                            anexo9: response.data.datoPuntosTratados[8].anexosRegistros,
                            mejora9: response.data.datoPuntosTratados[8].mejorasPropuestas,

                            entrada10: response.data.datoPuntosTratados[9].entradaConclusiones,
                            anexo10: response.data.datoPuntosTratados[9].anexosRegistros,
                            mejora10: response.data.datoPuntosTratados[9].mejorasPropuestas,

                            entrada11: response.data.datoPuntosTratados[10].entradaConclusiones,
                            anexo11: response.data.datoPuntosTratados[10].anexosRegistros,
                            mejora11: response.data.datoPuntosTratados[10].mejorasPropuestas,

                            entrada12: response.data.datoPuntosTratados[11].entradaConclusiones,

                            entrada13: response.data.datoPuntosTratados[12].entradaConclusiones,
                            anexo13: response.data.datoPuntosTratados[12].anexosRegistros,
                            mejora13: response.data.datoPuntosTratados[12].mejorasPropuestas,

                            entrada14: response.data.datoPuntosTratados[13].entradaConclusiones,
                            anexo14: response.data.datoPuntosTratados[13].anexosRegistros,
                            mejora14: response.data.datoPuntosTratados[13].mejorasPropuestas,

                            entrada15: response.data.datoPuntosTratados[14].entradaConclusiones,
                            anexo15: response.data.datoPuntosTratados[14].anexosRegistros,
                            mejora15: response.data.datoPuntosTratados[14].mejorasPropuestas,

                            entrada16: response.data.datoPuntosTratados[15].entradaConclusiones,
                            anexo16: response.data.datoPuntosTratados[15].anexosRegistros,
                            mejora16: response.data.datoPuntosTratados[15].mejorasPropuestas,

                            entrada17: response.data.datoPuntosTratados[16].entradaConclusiones,
                            anexo17: response.data.datoPuntosTratados[16].anexosRegistros,
                            mejora17: response.data.datoPuntosTratados[16].mejorasPropuestas,
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "revision-direccion.docx");
                        me.disabled = false;
                    });

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });

     
        },
        eliminarRevision(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la revisión?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-revision-direccion", {
                            idRevisionDireccion: param.item.idRevisionDireccion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarRevisionesDireccion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;

            this.listarRevisionesDireccion();
        }
    }

}
</script>
